const DATA = [
  {
    name: 'instagram',
    link: '#',
  },
  {
    name: 'facebook',
    link: '#',
  },
  {
    name: 'twitter',
    link: '#',
  },
  {
    name: 'linkedin',
    link: '#',
  },
]

export default DATA
