const MENU = [
  {
    name: 'About us',
    link: '/about',
  },
  {
    name: 'Product',
    link: '/products',
  },
]

export default MENU
