import React, { useState } from 'react'
import { Button, Dropdown } from 'react-bootstrap'
import Menu from '../../Menu'

const NavMobile = () => {
  const [open, setOpen] = useState(false)

  const handleToggle = () => setOpen((prev) => !prev)

  return (
    <Dropdown show={open} onToggle={handleToggle}>
      <Dropdown.Toggle variant="dark">
        <span />
      </Dropdown.Toggle>

      <Dropdown.Menu
        align="end"
        popperConfig={{
          modifiers: [{ name: 'offset', options: { offset: [6, -46] } }],
        }}
      >
        <Menu onClick={handleToggle} variant="dropdown" />

        <Dropdown.Divider />
        <Button
          href="https://calendly.com/nufii"
          target="_blank"
          rel="noopener noreferrer"
          className="dropdown-btn"
        >
          Schedule demo
        </Button>
      </Dropdown.Menu>
    </Dropdown>
  )
}

export default NavMobile
