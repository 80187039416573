import React from 'react'
import cn from 'classnames'
import Icon from '~components/Icon'
import DATA from './constants'
import * as s from './Social.module.scss'

const Social = ({ className }) => (
  <ul className={cn(s.social, className)}>
    {DATA.map(({ name, link }) => (
      <li key={name}>
        <a
          href={link}
          target="_blank"
          rel="noreferrer"
          className={s.social_link}
        >
          <Icon name={`icon-${name}`} />
        </a>
      </li>
    ))}
  </ul>
)

export default Social
