import React from 'react'
import { Button, Container } from 'react-bootstrap'
import PropTypes from 'prop-types'
import logo from '~img/logo.svg'
import { Link } from 'gatsby'
import Menu from '../Menu'
import * as s from './Header.module.scss'
import NavMobile from './NavMobile'

const Header = ({ siteTitle }) => (
  <Container as="header" className={s.header}>
    <Link to="/">
      <img width="102" height="40" src={logo} alt={siteTitle} />
    </Link>

    <Menu variant="header" />

    <div className={s.header_btn}>
      <Button
        size="sm"
        href="https://calendly.com/nufii"
        target="_blank"
        rel="noopener noreferrer"
      >
        Schedule demo
      </Button>
    </div>

    <NavMobile />
  </Container>
)

Header.defaultProps = {
  siteTitle: '',
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

export default Header
