import React from 'react'
import { Container } from 'react-bootstrap'
import PropTypes from 'prop-types'
import Social from '~components/Social'
import Menu from '../Menu'
import * as s from './Footer.module.scss'

const Footer = ({ siteTitle }) => {
  const currentYear = new Date().getFullYear()

  return (
    <Container as="footer" className={s.footer}>
      <div className={s.footer_item}>
        <Menu variant="footer" />

        <Social className={s.footer_socials} />
      </div>

      <div className={s.footer_item}>
        <p>
          © {currentYear} {siteTitle}. All rights reserved
        </p>

        <p className={s.footer_copy}>
          Designed and developed by{' '}
          <a href="https://min.studio/" target="_blank" rel="noreferrer">
            Minimal Studio
          </a>
        </p>
      </div>
    </Container>
  )
}

Footer.defaultProps = {
  siteTitle: '',
}

Footer.propTypes = {
  siteTitle: PropTypes.string,
}

export default Footer
